<template>
  <icon-question-filled ref="icon"
                        width="16"
                        height="16"
                        color="#999ca7" />
</template>

<script>
import tippy from 'tippy.js'

export default {
  mounted () {
    tippy(this.$refs.icon.$el, {
      content: 'The request has successful code, however it is marked failed. The reason is the response did not met conditions specified in monitor settings.'
    })
  }
}
</script>
